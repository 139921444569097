import { useNavigate } from 'react-router-dom';
import "./ButtonPrimaryWithIcon.css";

const ButtonPrimaryWithIcon = () => {
  const navigate = useNavigate(); // Hook for navigating

  const handleClick = () => {
    navigate('/signup'); // Redirect to signup page
  };

  return (
    <div className="button-primary-with-icon" onClick={handleClick}>
      <div className="frame-1">
        <div className="button-name">Start free trial now </div>
      </div>
    </div>
  );
};

export default ButtonPrimaryWithIcon;
