import Login from "./pages/Login/login";
import LandingPage from './pages/LandingPage/LandingPage';
import Signup from "./pages/SignUp/Signup";
import HistoryDetail from './pages/scr_check/scr_check'
import SpeechToTextComparison from './pages/scr_check/live_compare'
import Home from './pages/home/home'
import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import "./styles.css";
import axios from "axios";

function App() {
  useEffect(() => {
    // Set the Authorization header when the component mounts
    const token = localStorage.getItem('token');
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
}, []);
  return (
    <div className="App">
      <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/home" element={<Home />} />
        <Route path="/histories" element={<Home />} />
        <Route path="/trans" element={<SpeechToTextComparison />} />
      </Routes>
    </Router>
    </div>
  );
}

export default App;
