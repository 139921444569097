import React, { useState, useEffect, useRef } from "react";
import "./project.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import ProjectPopup from '../../home/popup_new_project/popup_new_project'


const Project = () => {

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const popupRef = useRef(null); // Ref for the popup container


  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const closePopup = () => {
    setIsPopupVisible(false);
  };

  // Check if a click is outside the popup
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closePopup(); // Close popup if clicked outside
      }
    };

    if (isPopupVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPopupVisible]);

  return (
    <div className="">
      <div className="rectangle-1015">
        <div className="listen-compare-and-grow">Listen, Compare, and Grow!</div>
        <div className="lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit-donec-non-neque-non-nibh-pretium-gravida-aliquam-consequat">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          <br />
          Donec non neque non nibh pretium gravida. Aliquam consequat
        </div>
        <div className="rectangle-1014" onClick={togglePopup}>
          <div className="group-446">
            <img className="frame3" src={`${process.env.PUBLIC_URL}/assets/images/frame2.svg`} alt="Frame 2" />
            <div className="start-a-new-project">Start a new project</div>
          </div>
        </div>
      </div>

      <div className="rectangle-1016">
        <div className="recent-projects">Recent Projects</div>
        <div class="row row_width">
          <div class="col-sm-12 col-md-6 col-lg-3">
            <div className="frame-13">
              <div className="poem_flex">
                <div className="poems">Poems</div>
                <img className="vector" src={`${process.env.PUBLIC_URL}/assets/images/vector0.svg`} alt="Vector 0" />
              </div>
              <div className="little-leaf-upon-the-tree-dancing-swaying-wild-and-free-the-wind">
                Little Leaf upon the tree, Dancing, swaying, wild and free. The wind...
              </div>
              <div className="_11-11-2024">11-11-2024</div>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-3">
            <div className="frame-13">
              <div className="poem_flex">
                <div className="poems">Poems</div>
                <img className="vector" src={`${process.env.PUBLIC_URL}/assets/images/vector0.svg`} alt="Vector 0" />
              </div>
              <div className="little-leaf-upon-the-tree-dancing-swaying-wild-and-free-the-wind">
                Little Leaf upon the tree, Dancing, swaying, wild and free. The wind...
              </div>
              <div className="_11-11-2024">11-11-2024</div>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-3">
            <div className="frame-13">
              <div className="poem_flex">
                <div className="poems">Poems</div>
                <img className="vector" src={`${process.env.PUBLIC_URL}/assets/images/vector0.svg`} alt="Vector 0" />
              </div>
              <div className="little-leaf-upon-the-tree-dancing-swaying-wild-and-free-the-wind">
                Little Leaf upon the tree, Dancing, swaying, wild and free. The wind...
              </div>
              <div className="_11-11-2024">11-11-2024</div>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-3">
            <div className="frame-13">
              <div className="poem_flex">
                <div className="poems">Poems</div>
                <img className="vector" src={`${process.env.PUBLIC_URL}/assets/images/vector0.svg`} alt="Vector 0" />
              </div>
              <div className="little-leaf-upon-the-tree-dancing-swaying-wild-and-free-the-wind">
                Little Leaf upon the tree, Dancing, swaying, wild and free. The wind...
              </div>
              <div className="_11-11-2024">11-11-2024</div>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-3">
            <div className="frame-13">
              <div className="poem_flex">
                <div className="poems">Poems</div>
                <img className="vector" src={`${process.env.PUBLIC_URL}/assets/images/vector0.svg`} alt="Vector 0" />
              </div>
              <div className="little-leaf-upon-the-tree-dancing-swaying-wild-and-free-the-wind">
                Little Leaf upon the tree, Dancing, swaying, wild and free. The wind...
              </div>
              <div className="_11-11-2024">11-11-2024</div>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-3">
            <div className="frame-13">
              <div className="poem_flex">
                <div className="poems">Poems</div>
                <img className="vector" src={`${process.env.PUBLIC_URL}/assets/images/vector0.svg`} alt="Vector 0" />
              </div>
              <div className="little-leaf-upon-the-tree-dancing-swaying-wild-and-free-the-wind">
                Little Leaf upon the tree, Dancing, swaying, wild and free. The wind...
              </div>
              <div className="_11-11-2024">11-11-2024</div>
            </div>
          </div>
          


        </div>
      </div>

      {isPopupVisible && (
        <div className="popup-overlay">
          <div className="popup-content" ref={popupRef}>
            <ProjectPopup onClose={closePopup} />
          </div>
        </div>
      )}

    </div>
  );
};

export default Project;
