import React, { useState, useEffect } from "react";
import "../../vars.css";
import "./home.css";
import SideBar from '../../components/home/sidebar/sidebar';
import Navbar from '../../components/home/navbar/navbar';
import 'bootstrap/dist/css/bootstrap.min.css';


const Home = () => {
  // State to track window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update window width on resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="project">

      <Navbar rgb={true} englishandvector={true} cooperandelipse={true} profile={true}></Navbar>

      <SideBar />
    </div>
  );
};

export default Home;
