import React from "react";
import "./popup_new_project.css";

const ProjectPopup = () => {
  return (
    <div>
        <div className="popup-frame">
            <div className="popup-header-frame">
                <div className="popup-header-bg">
                    <div>
                        <img className="popup-logo" src={`${process.env.PUBLIC_URL}/assets/images/frame_plus.svg`} alt="Logo"/>
                    </div>
                    <div className="popup-title-frame">
                        <div className="popup-title-text">Start a New Project</div>
                    </div>
                </div>
            </div>
            <div className="popup-project-title-frame">
                <div className="popup-project-title-label">Project Title</div>
            </div>
    
            <div className="popup-input-frame">
                <div className="popup-input-text">Nursery Rhymes</div>
            </div>

            <div className="footer_content">
                <div className="popup-attempt-frame">
                    <div className="popup-attempt-text">Daily attempts left: 04</div>
                </div>

                <div className="popup-create-bg-frame">
                    <div className="popup-create-bg">
                    <div className="popup-create-btn-frame">
                        <div className="popup-create-btn-text">Create Now</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default ProjectPopup;
