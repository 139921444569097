import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios'; // Assuming axiosInstance is already set
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './login.css'
import Logo from '../../components/Logo/Logo/Logo';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [token, setToken] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    // Update isMobile state based on screen resize
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Function to get CSRF token from cookies
  const getCSRFToken = () => {
    const match = document.cookie.match(/csrftoken=([^;]+)/);
    return match ? match[1] : '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append('username', username);
    data.append('password', password);

    try {
      const csrfToken = getCSRFToken(); // Get the CSRF token dynamically

      const response = await axiosInstance.post('accounts/token/', data, {
        headers: {
          'X-CSRFToken': csrfToken, // Pass CSRF token in headers
        }
      });

      if (response.data.access) {
        setToken(response.data.access);
        localStorage.setItem('token', response.data.access);

        setError('');
        console.log('Login successful!', response);

        window.location.href = '/home'; // Replace '/home' with your actual home route
      } else {
        setError('Invalid Email / Mobile number or password');
        console.error('Error: Authentication failed');
      }
    } catch (err) {
      setError('Invalid credentials');
      console.error('Error logging in:', err);
    }
  };

  const textStyle = {
    fontSize: isMobile ? '11px' : '12px',
    fontFamily: 'Outfit',
    margin: 0,
    whiteSpace: 'nowrap',
  };

  return (
    <div className='main-div'>
      {/* Logo Section */}
      <Logo></Logo>

      {/* Form Container */}
      <div className="form-container">
        <h2 className='login-title'>Log in</h2>
        <p className='welcome-text'>Welcome Back</p>
        
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <div style={{ marginBottom: '16px' }}>
            <label className='username-label'>Email / Mobile number</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              className='username-input'
              placeholder="johndoe@mail.com"
            />
          </div>

          <div style={{ marginBottom: '16px' }}>
            <label className="password-label">Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className='password-input'
            />
          </div>

          <button
            type="submit" className='submit-button'>
            Log in
          </button>
        </form>

        {error && <p className="error">{error}</p>}
        
        <div style={{ textAlign: 'center', marginTop: '5px' }}>
          <Link to="/forgot-password" className="forgot-link">
            Forget password?
          </Link>
        </div>

        <div className="dont_parent">
          <p style={{ ...textStyle, color: '#808080' }}>
            Don't have an account?&nbsp;
          </p>
          <Link to="/signup" style={{
            ...textStyle
          }} className='signup_link'>
            Create account now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
