import React from "react";
import "./history.css";
import 'bootstrap/dist/css/bootstrap.min.css';

const History = () => {
  return (
    <div className="">
      <div className="dashed-container">
        <div className="section-heading">Recent Projects</div>
        <div className="row row_width">
          <div className="col-sm-12 col-md-6 col-lg-3">
            <div className="card-container">
              <div className="card-header">
                <div className="card-title">Poems</div>
                <img className="icon" src={`${process.env.PUBLIC_URL}/assets/images/vector0.svg`} alt="Vector 0" />
              </div>
              <div className="card-text">
                Little Leaf upon the tree, Dancing, swaying, wild and free. The wind...
              </div>
              <div className="card-date">11-11-2024</div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-3">
            <div className="card-container">
              <div className="card-header">
                <div className="card-title">Poems</div>
                <img className="icon" src={`${process.env.PUBLIC_URL}/assets/images/vector0.svg`} alt="Vector 0" />
              </div>
              <div className="card-text">
                Little Leaf upon the tree, Dancing, swaying, wild and free. The wind...
              </div>
              <div className="card-date">11-11-2024</div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-3">
            <div className="card-container">
              <div className="card-header">
                <div className="card-title">Poems</div>
                <img className="icon" src={`${process.env.PUBLIC_URL}/assets/images/vector0.svg`} alt="Vector 0" />
              </div>
              <div className="card-text">
                Little Leaf upon the tree, Dancing, swaying, wild and free. The wind...
              </div>
              <div className="card-date">11-11-2024</div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-3">
            <div className="card-container">
              <div className="card-header">
                <div className="card-title">Poems</div>
                <img className="icon" src={`${process.env.PUBLIC_URL}/assets/images/vector0.svg`} alt="Vector 0" />
              </div>
              <div className="card-text">
                Little Leaf upon the tree, Dancing, swaying, wild and free. The wind...
              </div>
              <div className="card-date">11-11-2024</div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-3">
            <div className="card-container">
              <div className="card-header">
                <div className="card-title">Poems</div>
                <img className="icon" src={`${process.env.PUBLIC_URL}/assets/images/vector0.svg`} alt="Vector 0" />
              </div>
              <div className="card-text">
                Little Leaf upon the tree, Dancing, swaying, wild and free. The wind...
              </div>
              <div className="card-date">11-11-2024</div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-3">
            <div className="card-container">
              <div className="card-header">
                <div className="card-title">Poems</div>
                <img className="icon" src={`${process.env.PUBLIC_URL}/assets/images/vector0.svg`} alt="Vector 0" />
              </div>
              <div className="card-text">
                Little Leaf upon the tree, Dancing, swaying, wild and free. The wind...
              </div>
              <div className="card-date">11-11-2024</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
