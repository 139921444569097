import React, { useState, useEffect } from "react";
import { SketchPicker } from "react-color";
import { useNavigate } from "react-router-dom";
import "./navbar.css";
import SideWindow from "../../../components/LandingPage/sidewindow/sidewindow.jsx";
import { updateProfileColors, fetchUserProfile, blackilist } from '../../../apis/main_apis.jsx';



const Navbar = ({ rgb, englishandvector, cooperandelipse ,profile=null}) => {
    const navigater = useNavigate();  // Now useNavigate can be used inside a functional component
    const [userProfile, setUserProfile] = useState(profile);  // State for storing profile data
    const [userProfilefirstletter, setUserProfilefirstletter] = useState("");
    const [colors, setColors] = useState({
        correctWordColor: "#0D8422",
        incorrectWordColor: "#e23a10",
        approximateMatchColor: "#D9A600",
    });

    useEffect(() => {
        // Fetch user profile
        const fetchUserProfileData = async () => {
          try {
            const response = await fetchUserProfile();
            // Update colors state with fetched values
            setColors((prevColors) => ({
              correctWordColor: response.success || prevColors.correctWordColor,
              incorrectWordColor: response.fail || prevColors.incorrectWordColor,
              approximateMatchColor: response.warning || prevColors.approximateMatchColor,
            }));

            setUserProfile(response.name);  // Set fetched profile data

            const profileName = response.name || "";
            const firstLetter = typeof profileName === "string" ? profileName.charAt(0).toUpperCase() : "C";
            
            // Update state with processed value
            setUserProfilefirstletter(firstLetter);
          } catch (error) {
            console.error('Error fetching profile or colors:', error);
          }
        };
        fetchUserProfileData();
    }, [colors.approximateMatchColor, colors.correctWordColor, colors.incorrectWordColor]);

    const HandleApiCall = async (navigate) => {
        try {
            
            const response = await blackilist()
            console.log(response)
            navigater('/login');
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.log("Token expired or invalid");
                localStorage.removeItem('token');
                localStorage.removeItem('refresh_token');
                navigater('/login');  // Pass navigate from the component to redirect
            } else {
                console.error('API error:', error);
            }
        }
    };
    const [isSideWindowOpen, setIsSideWindowOpen] = useState(false);

    const toggleSideWindow = () => {
        setIsSideWindowOpen(!isSideWindowOpen);
    };

    const [ellipsePopupVisible, setEllipsePopupVisible] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [colorPickerVisible, setColorPickerVisible] = useState(null);

    const [languagePopupVisible, setLanguagePopupVisible] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState("English");

    const handleLogoClick = () => {
        navigater("/home");
    };

    const toggleEllipsePopup = () => {
        setEllipsePopupVisible((prev) => !prev);
    };

    const handleEllipseOptionClick = (option) => {
        if (option === "Logout") {
            HandleApiCall();  // Call logout when the user clicks the logout option
        } else {
            console.log(option);
        }
        setEllipsePopupVisible(false);
    };

    const toggleDropdown = () => {
        setShowDropdown((prev) => !prev);
    };

    const handleColorChange = async (color, type) => {
        const updatedColors = {
            ...colors,
            [type]: color.hex,
        };
    
        setColors(updatedColors); // Update local state to reflect the selected color
    
        try {
            // Send the updated colors and language to the backend
            await updateProfileColors(updatedColors);
            console.log("Colors and language updated successfully");
        } catch (error) {
            console.error("Error updating colors and language:", error);
        }
    
        setColorPickerVisible(null);
    };

    const toggleColorPicker = (boxType, event) => {
        event.stopPropagation();
        setColorPickerVisible(colorPickerVisible === boxType ? null : boxType);
    };

    const toggleLanguagePopup = () => {
        setLanguagePopupVisible((prev) => !prev);
    };

    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
        setLanguagePopupVisible(false);
        localStorage.setItem("selectedLanguage", language); // Save language to localStorage

    };

    useEffect(() => {
        // Retrieve the selected language from localStorage
        const savedLanguage = localStorage.getItem("selectedLanguage");
        if (savedLanguage) {
            setSelectedLanguage(savedLanguage); // Set the language from localStorage
        }
    }, []);

    return (
        <div className="nav">
            <div className="rectangle-997">
                <div className="frame-3">
                    <img
                        className="group-2"
                        src={`${process.env.PUBLIC_URL}/assets/images/group-20.svg`}
                        alt="Group 2"
                        onClick={handleLogoClick}
                    />
                </div>

                {/* Navbar toggler for responsive view */}
                <button 
                    className="navbar-toggler" 
                    onClick={toggleSideWindow} 
                    aria-label="Toggle Navigation"
                >
                    <i className="fas fa-bars"></i>
                </button>

                <div className="section2">
                    {rgb && (
                        <div className="group-15" onClick={toggleDropdown}>
                            <img
                                className="rgb-color-wheel"
                                src={`${process.env.PUBLIC_URL}/assets/images/rgb-color-wheel0.png`}
                                alt="RGB Color Wheel"
                            />
                            {showDropdown && (
                                <div className="dropd-menu">
                                    <div className="dropdown-item">
                                        <div
                                            className="flex_color"
                                            onClick={(e) => toggleColorPicker("correctWordColor", e)}
                                        >
                                            Correct Word Color
                                            <div
                                                className="box1"
                                                style={{ backgroundColor: colors.correctWordColor }}
                                            ></div>
                                        </div>
                                    </div>
                                    <div className="dropdown-item">
                                        <div
                                            className="flex_color"
                                            onClick={(e) => toggleColorPicker("incorrectWordColor", e)}
                                        >
                                            Incorrect Word Color
                                            <div
                                                className="box2"
                                                style={{ backgroundColor: colors.incorrectWordColor }}
                                            ></div>
                                        </div>
                                    </div>
                                    <div className="dropdown-item">
                                        <div
                                            className="flex_color"
                                            onClick={(e) => toggleColorPicker("approximateMatchColor", e)}
                                        >
                                            Approximate Match Color
                                            <div
                                                className="box3"
                                                style={{ backgroundColor: colors.approximateMatchColor }}
                                            ></div>
                                        </div>
                                    </div>
                                    {colorPickerVisible && (
                                        <SketchPicker
                                            color={colors[colorPickerVisible]}
                                            onChangeComplete={(color) => handleColorChange(color, colorPickerVisible)}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    )}

                    {englishandvector && (
                        <>
                            <div className="english">{selectedLanguage}</div>
                            <img
                                className="vector-1"
                                src={`${process.env.PUBLIC_URL}/assets/images/vector-10.svg`}
                                alt="Vector 1"
                                onClick={toggleLanguagePopup}
                            />
                            {languagePopupVisible && (
                                <div className="language-popup">
                                    <div
                                        className="language-option"
                                        onClick={() => handleLanguageChange("English")}
                                    >
                                        English
                                    </div>
                                    <div
                                        className="language-option"
                                        onClick={() => handleLanguageChange("Arabic")}
                                    >
                                        Arabic
                                    </div>
                                </div>
                            )}
                        </>
                    )}

                    {cooperandelipse && (
                        <div className="component-2">
                            <div className="cooper-vaccaro">{userProfile}</div>
                            <div className="ellipse-12" onClick={toggleEllipsePopup}>
                                <div className="c">{userProfilefirstletter}</div>
                            </div>
                            {ellipsePopupVisible && (
                                <div className="ellipse-popup">
                                    <div
                                        className="ellipse-option"
                                        onClick={() => handleEllipseOptionClick("My Account")}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none" className="popup_svg">
                                            <g clip-path="url(#clip0_82_1656)">
                                                <path d="M6 11.1854C8.76142 11.1854 11 8.94685 11 6.18542C11 3.424 8.76142 1.18542 6 1.18542C3.23858 1.18542 1 3.424 1 6.18542C1 8.94685 3.23858 11.1854 6 11.1854Z" stroke="#5A5A5A" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M6 6.68542C6.82843 6.68542 7.5 6.01385 7.5 5.18542C7.5 4.357 6.82843 3.68542 6 3.68542C5.17157 3.68542 4.5 4.357 4.5 5.18542C4.5 6.01385 5.17157 6.68542 6 6.68542Z" stroke="#5A5A5A" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M3.5 10.5164V9.68542C3.5 9.42021 3.60536 9.16585 3.79289 8.97832C3.98043 8.79078 4.23478 8.68542 4.5 8.68542H7.5C7.76522 8.68542 8.01957 8.79078 8.20711 8.97832C8.39464 9.16585 8.5 9.42021 8.5 9.68542V10.5164" stroke="#5A5A5A" stroke-linecap="round" stroke-linejoin="round"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_82_1656">
                                                <rect width="12" height="12" fill="white" transform="translate(0 0.185425)"/>
                                                </clipPath>
                                            </defs>
                                            </svg>
                                        My Account
                                    </div>
                                    <div
                                        className="ellipse-option"
                                        onClick={() => handleEllipseOptionClick("Upgrade Plan")}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none" className="popup_svg">
                                            <g clip-path="url(#clip0_78_1588)">
                                                <path d="M6 11.1854C8.76142 11.1854 11 8.94685 11 6.18542C11 3.424 8.76142 1.18542 6 1.18542C3.23858 1.18542 1 3.424 1 6.18542C1 8.94685 3.23858 11.1854 6 11.1854Z" stroke="#5A5A5A" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M4 6.18542H8" stroke="#5A5A5A" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M6 4.18542V8.18542" stroke="#5A5A5A" stroke-linecap="round" stroke-linejoin="round"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_78_1588">
                                                <rect width="12" height="12" fill="white" transform="translate(0 0.185425)"/>
                                                </clipPath>
                                            </defs>
                                            </svg>
                                        Upgrade Plan
                                    </div>
                                    <div
                                        className="ellipse-option"
                                        onClick={() => handleEllipseOptionClick("Logout")}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none" className="popup_svg">
                                            <path d="M4.5 10.6854H2.5C2.23478 10.6854 1.98043 10.5801 1.79289 10.3925C1.60536 10.205 1.5 9.95064 1.5 9.68542V2.68542C1.5 2.42021 1.60536 2.16585 1.79289 1.97832C1.98043 1.79078 2.23478 1.68542 2.5 1.68542H4.5" stroke="#5A5A5A" stroke-width="0.916667" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M8 8.68545L10.5 6.18546L8 3.68546" stroke="#5A5A5A" stroke-width="0.916667" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M10.5 6.18542H4.5" stroke="#5A5A5A" stroke-width="0.916667" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        Logout
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            {/* Reusable side window component */}
            <SideWindow
                isOpen={isSideWindowOpen}
                onClose={toggleSideWindow}
                profile={profile}
                user={{'name': userProfile}}
                colors={colors}
            />
        </div>
    );
};

export default Navbar;
