import React, { useState, useRef, useEffect } from "react";
import axios from "../../axios";

const SpeechToText = () => {
  const [text, setText] = useState("");
  const [extraText, setExtraText] = useState(""); // New state for extra text field
  const [isRecording, setIsRecording] = useState(false);
  const [formattedText, setFormattedText] = useState("");

  const mediaRecorderRef = useRef(null); // Reference for MediaRecorder
  const audioChunksRef = useRef([]); // To store audio chunks
  const recognitionRef = useRef(null); // SpeechRecognition reference
  const audioStreamRef = useRef(null); // Audio stream reference

  // Handle real-time text comparison via API
  useEffect(() => {
    const fetchFormattedText = async () => {
      if (!text) return; // Only call if text is not empty

      try {
        const formData = new FormData();
        formData.append("text", extraText); // Add extra text if any
        formData.append("input_type", "text"); // Specify input type
        formData.append("audio_text", text); // Send the speech-to-text result
        formData.append("user_id", 1); // Example additional parameter

        const response = await axios.post("/api/compare/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setFormattedText(response.data.formatted); // Update formatted text state
      } catch (error) {
        console.error("Error fetching formatted text:", error);
      }
    };

    fetchFormattedText();
  }, [text]); // Trigger the API call when text changes

  // Start recording function (both for MediaRecorder and Speech Recognition)
  const handleStartRecording = () => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!SpeechRecognition) {
      alert("Speech Recognition API is not supported in your browser.");
      return;
    }

    if (!recognitionRef.current) {
      const recognition = new SpeechRecognition();
      recognition.lang = "en-US";
      recognition.continuous = true;
      recognition.interimResults = true;

      recognition.onresult = (event) => {
        const transcript = Array.from(event.results)
          .map((result) => result[0].transcript)
          .join("");
        setText(transcript); // Update text state with speech-to-text result
      };

      recognition.onerror = (event) => {
        console.error("Speech recognition error: ", event.error);
      };

      recognition.onend = () => {
        if (isRecording) {
          recognition.start();
        } else {
          setIsRecording(false);
        }
      };

      recognitionRef.current = recognition;
    }

    // Set up audio recording via MediaRecorder
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        audioStreamRef.current = stream; // Store the audio stream
        mediaRecorderRef.current = new MediaRecorder(stream);
        
        mediaRecorderRef.current.ondataavailable = (event) => {
          console.log("Audio data available:", event.data);
          audioChunksRef.current.push(event.data); // Push audio data to the chunks array
        };

        mediaRecorderRef.current.onstop = () => {
          console.log("MediaRecorder stopped");
          // Ensure audio chunks are available when recording stops
          if (audioChunksRef.current.length > 0) {
            const audioBlob = new Blob(audioChunksRef.current, {
              type: "audio/wav",
            });
            console.log("Audio Blob ready to be sent:", audioBlob);
            handleSendAudio(audioBlob); // Send the audio data to the server
          } else {
            console.error("No audio data captured.");
          }
        };

        mediaRecorderRef.current.start(); // Start media recording
        console.log("MediaRecorder started.");
        setIsRecording(true);
      })
      .catch((error) => {
        console.error("Error accessing audio stream: ", error);
      });

    recognitionRef.current.start(); // Start speech recognition
  };

  const handleStopRecording = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop(); // Stop speech recognition
      setIsRecording(false);
    }

    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop(); // Stop the media recorder
      console.log("Recording stopped.");
    }
  };

  // Send audio blob to the server
  const handleSendAudio = async (audioBlob) => {
    console.log("ggg")
    const formData = new FormData();
    formData.append("full_audio", audioBlob, "audio.wav"); // Send the full audio
    formData.append("input_type", "text"); // Specify input type as audio
    formData.append("text", extraText); // Add extra text if any
    formData.append("user_id", 1); // Example additional parameter

    try {
      console.log("Sending API request...");
      const response = await axios.post("/api/compare/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("API response:", response.data);
      setFormattedText(response.data.formatted); // Update formatted text state
    } catch (error) {
      console.error("Error sending audio:", error);
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <h1>Speech to Text</h1>
      <textarea
        rows="10"
        cols="50"
        value={text}
        placeholder="Start speaking..."
        readOnly
      ></textarea>
      <div style={{ marginTop: "10px" }}>
        {!isRecording ? (
          <button onClick={handleStartRecording}>Start Recording</button>
        ) : (
          <button onClick={handleStopRecording}>Stop Recording</button>
        )}
      </div>
      <div style={{ marginTop: "10px" }}>
        <input
          type="text"
          value={extraText}
          onChange={(e) => setExtraText(e.target.value)} // Update extraText on change
          placeholder="Enter additional text"
        />
      </div>
      {formattedText && (
        <div style={{ marginTop: "20px" }}>
          <h2>Formatted Text</h2>
          <p>{formattedText}</p>
        </div>
      )}
    </div>
  );
};

export default SpeechToText;
