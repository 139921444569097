import React from "react";
import { useLocation, Link } from "react-router-dom";
import "./sidebar.css";
import Project from "../project/project";
import History from "../history/history";

const SideBar = () => {
  const location = useLocation();
  const isHomePage = location.pathname === "/home";

  return (
    <div className="main-container">
      {/* Sidebar */}
      <div className="sidebar_">
        <div className="rectangle-998">
          <Link to="/home"  className={`menus ${isHomePage ? "rectangle-5" : "non_selected"}`}>
            <div className={`${isHomePage ? "frame-5" : "frame-4"}`}>
              <img
                className={isHomePage ? "frame2" : "frame"}
                src={`${process.env.PUBLIC_URL}/assets/images/${isHomePage ? "frame1" : "Frame12"}.svg`}
                alt={isHomePage ? "Frame 1" : "Frame 12"}
              />
            </div>
            <div className={`${isHomePage ? "project2" : "history"}`}>Project</div>
          </Link>

          <Link to="/histories" className={`menus ${isHomePage ? "non_selected" : "rectangle-5"}`}>
            <div className={` ${isHomePage ? "frame-4" : "frame-5"}`}>
              <img
                className={`${isHomePage ? "frame" : "frame2"}`}
                src={`${process.env.PUBLIC_URL}/assets/images/${isHomePage ? "Frame00" : "frame0"}.svg`}
                alt="Frame 0"
              />
            </div>
            <div className={`${isHomePage ? "history" : "project2"}`}>History</div>
          </Link>

          <div className="rectangle-33">
            <div className="current-plan">Current Plan</div>
            <div className="free-plan">Free Plan</div>
            <div className="daily-attempt-left-03">Daily Attempt left: 03</div>
            <div className="plan-validity-end">Plan Validity End:</div>
            <div className="_10-11-2024">10-11-2024</div>
            <div className="rectangle-34">
              <div className="upgrade-now">Upgrade Now</div>
            </div>
          </div>
        </div>
      </div>

      {/* Render components based on URL */}
      {location.pathname === "/home" && <Project />}
      {location.pathname === "/histories" && <History />}
    </div>
  );
};

export default SideBar;
