import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import axiosInstance from '../../axios';
import html2canvas from 'html2canvas';  // Import html2canvas for screenshot capture
import { FaShareAlt } from 'react-icons/fa';  // Importing FontAwesome share icon

const HistoryDetail = () => {
  const [historyData, setHistoryData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showSharePopup, setShowSharePopup] = useState(false);  // State to control the popup visibility
  const navigate = useNavigate();

  // Check if the token is expired
  const isTokenExpired = (token) => {
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decoded.exp < currentTime;
    } catch (error) {
      return true;
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token && isTokenExpired(token)) {
      navigate('/login');
    }
  }, [navigate]);

  // Fetch history data
  const fetchHistoryData = () => {
    setLoading(true);
    setError(null);
    axiosInstance
      .get("history/history-detail/?history_id=1")
      .then((response) => {
        setHistoryData(response.data.data);
        setLoading(false);
        captureAndUploadScreenshot(response.data.data);  // Pass fetched data to captureAndUploadScreenshot
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setError("Error fetching data: " + error.message);
        setLoading(false);
      });
  };

  // Function to capture screenshot and upload
  const captureAndUploadScreenshot = (historyData) => {
    html2canvas(document.body).then(canvas => {
      const base64Image = canvas.toDataURL();  // Get base64 encoded image
      const historyId = historyData.id;  // Use the correct history ID

      // Send the screenshot to the backend
      axiosInstance
        .post('history/screenshot-image/', {
          history_id: historyId,
          src_image: base64Image
        }, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}` // Include the token in headers
          }
        })
        .then((response) => {
          console.log("Screenshot uploaded successfully:", response.data);
          setHistoryData(prevData => ({
            ...prevData,
            scr_image: response.data.scr_image
          }));

          // Now show the share popup after the screenshot upload is successful
          setShowSharePopup(true);
        })
        .catch((error) => {
          console.error('Error uploading screenshot:', error);
          setError("Error uploading screenshot: " + error.message);
        });
    });
  };

  // Social Media share functions
  const shareToWhatsApp = () => {
    const imageUrl = historyData ? `http://127.0.0.1:8000${historyData.scr_image}` : '';
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(imageUrl)}`;
    window.open(whatsappUrl, "_blank");
  };
  
  const shareToInstagram = () => {
    const imageUrl = historyData ? `http://127.0.0.1:8000${historyData.scr_image}` : '';
    const instagramUrl = `https://www.instagram.com/sharer.php?u=${encodeURIComponent(imageUrl)}`;
    window.open(instagramUrl, "_blank");
  };
  
  const shareToFacebook = () => {
    const imageUrl = historyData ? `http://127.0.0.1:8000${historyData.scr_image}` : '';
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(imageUrl)}`;
    window.open(facebookUrl, "_blank");
  };
  
  const shareToTwitter = () => {
    const imageUrl = historyData ? `http://127.0.0.1:8000${historyData.scr_image}` : '';
    const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(imageUrl)}`;
    window.open(twitterUrl, "_blank");
  };

  // Toggle the popup visibility
  const toggleSharePopup = () => {
    setShowSharePopup(!showSharePopup);
  };

  // Share button click handler
  const handleShareClick = () => {
    fetchHistoryData();  // Fetch history data and trigger screenshot upload
  };

  return (
    <div>
      <h1>History Detail</h1>
      
      {/* Share Icon Button */}
      <button onClick={handleShareClick} style={shareButtonStyles}>
        <FaShareAlt size={24} />
      </button>

      {loading && <div>Loading...</div>}
      {error && <div style={{ color: 'red' }}>{error}</div>}

      {/* Popup for Social Media Sharing */}
      {showSharePopup && (
        <div style={popupStyles}>
          <div style={popupContentStyles}>
            <h3>Share this image</h3>
            {historyData?.scr_image ? (
              <img
                src={`http://127.0.0.1:8000${historyData.scr_image}`}
                alt="Screenshot"
                style={{ width: '100%', height: 'auto', marginBottom: '20px' }}
              />
            ) : (
              <p>No image available</p>
            )}
            <div>
              <button onClick={shareToWhatsApp}>Share to WhatsApp</button>
              <button onClick={shareToInstagram}>Share to Instagram</button>
              <button onClick={shareToFacebook}>Share to Facebook</button>
              <button onClick={shareToTwitter}>Share to Twitter</button>
            </div>
            <button onClick={toggleSharePopup} style={closeButtonStyles}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

// Styling for the popup and share button
const popupStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const popupContentStyles = {
  backgroundColor: 'white',
  padding: '20px',
  borderRadius: '8px',
  width: '400px',
  textAlign: 'center',
};

const closeButtonStyles = {
  marginTop: '10px',
  backgroundColor: 'red',
  color: 'white',
  border: 'none',
  padding: '10px',
  cursor: 'pointer',
};

const shareButtonStyles = {
  backgroundColor: '#007bff',
  color: 'white',
  border: 'none',
  padding: '10px 20px',
  cursor: 'pointer',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export default HistoryDetail;
