import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { SketchPicker } from "react-color";
import './sidewindow.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { updateProfileColors, fetchUserProfile } from '../../../apis/main_apis';

const SideWindow = ({ isOpen, onClose, menuItems = [], user = null ,profile=null}) => {
  const sideWindowRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false); // State for dropdown

  const [dropdownlang, setDropdownlang] = useState(false);

  const [colorPickerVisible, setColorPickerVisible] = useState(null);
    const [colors, setColors] = useState({
        correctWordColor: "#0D8422",
        incorrectWordColor: "#e23a10",
        approximateMatchColor: "#D9A600",
    });

    const handleColorChange = async (color, type) => {

      if (!profile) {
        console.error("Profile is not available.");
        return;
      }

      const updatedColors = {
          ...colors,
          [type]: color.hex,
      };
  
      setColors(updatedColors); // Update local state to reflect the selected color
  
      try {
          // Send the updated colors and language to the backend
          await updateProfileColors(updatedColors);
          console.log("Colors and language updated successfully");
      } catch (error) {
          console.error("Error updating colors and language:", error);
      }
  
      setColorPickerVisible(null);
  };

  const toggleColorPicker = (boxType, event) => {
      event.stopPropagation();
      setColorPickerVisible(colorPickerVisible === boxType ? null : boxType);
  };

  const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem('selectedLanguage') || 'English'); // Get language from localStorage

  const changeLanguage = (language) => {
    setCurrentLanguage(language);
    localStorage.setItem('selectedLanguage', language); // Save selected language to localStorage
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sideWindowRef.current && !sideWindowRef.current.contains(event.target)) {
        onClose();
        setDropdownOpen(false); // Close dropdown when clicking outside
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

    useEffect(() => {
      // Fetch user profile
      const fetchUserProfileData = async () => {
        if (!profile) {
          console.error("Profile is not available.");
          return;
        }
        try {
          const response = await fetchUserProfile();
          // Update colors state with fetched values
          setColors((prevColors) => ({
            correctWordColor: response.success || prevColors.correctWordColor,
            incorrectWordColor: response.fail || prevColors.incorrectWordColor,
            approximateMatchColor: response.warning || prevColors.approximateMatchColor,
          }));
        } catch (error) {
          console.error('Error fetching profile or colors:', error);
        }
      };
      fetchUserProfileData();
  }, [colors.approximateMatchColor, colors.correctWordColor, colors.incorrectWordColor]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
    setDropdownlang(false);
  };

  const toggleDropdownlang = () => {
    setDropdownlang(!dropdownlang);
    setDropdownOpen(false);
  };

  return (
    <div ref={sideWindowRef} className={`side-window ${isOpen ? 'open' : ''}`}>
      {/* Conditionally render profile if user data exists */}
      {user && user.name ? (
        <>
          <div className="header">
            <span className="user-name">{user.name}</span>
            <div className="ellipse-12">
                  <div className="c">{!user.profileImage && user.name && user.name[0] && user.name[0].toUpperCase()}</div>
              </div>
            {/* <div className="user-pic" style={{ backgroundImage: `url(${user.profileImage || '/path/to/default/image.jpg'})` }}></div> */}
          </div>
          <div className="divider"></div>
        </>
      ) : null}

      {/* Map through menuItems and render dynamically */}
      {menuItems.map((item, index) => (
        <Link key={index} to={item.path} className="menu-item">
          <i className={`fa ${item.icon}`} aria-hidden="true"></i>
          <span>{item.label}</span>
        </Link>
      ))}


      { profile ?
        (
          <div>
            <Link to='' className="menu-item">
              <i aria-hidden="true" className='profile'>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <g clip-path="url(#clip0_82_1731)">
                  <path d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z" stroke="#5A5A5A" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M6 6.5C6.82843 6.5 7.5 5.82843 7.5 5C7.5 4.17157 6.82843 3.5 6 3.5C5.17157 3.5 4.5 4.17157 4.5 5C4.5 5.82843 5.17157 6.5 6 6.5Z" stroke="#5A5A5A" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M3.5 10.331V9.5C3.5 9.23478 3.60536 8.98043 3.79289 8.79289C3.98043 8.60536 4.23478 8.5 4.5 8.5H7.5C7.76522 8.5 8.01957 8.60536 8.20711 8.79289C8.39464 8.98043 8.5 9.23478 8.5 9.5V10.331" stroke="#5A5A5A" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_82_1731">
                    <rect width="12" height="12" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              </i>
              <span>My Account</span>
            </Link>

            <Link to='' className="menu-item">
              <i aria-hidden="true" className='profile'>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <g clip-path="url(#clip0_78_1634)">
                  <path d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z" stroke="#5A5A5A" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M4 6H8" stroke="#5A5A5A" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M6 4V8" stroke="#5A5A5A" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_78_1634">
                    <rect width="12" height="12" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              </i>
              <span>Upgrade Plan</span>
            </Link>


            {/* Text Color Dropdown */}
          <div className="menu-item dropdown-container" onClick={toggleDropdown}>
            <i aria-hidden="true" className="profile">
              {/* Text Color Icon */}
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <g clip-path="url(#clip0_78_1647)">
                  <path d="M6.75 3.5C6.88807 3.5 7 3.38807 7 3.25C7 3.11193 6.88807 3 6.75 3C6.61193 3 6.5 3.11193 6.5 3.25C6.5 3.38807 6.61193 3.5 6.75 3.5Z" fill="#767676" stroke="#767676" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8.75 5.5C8.88807 5.5 9 5.38807 9 5.25C9 5.11193 8.88807 5 8.75 5C8.61193 5 8.5 5.11193 8.5 5.25C8.5 5.38807 8.61193 5.5 8.75 5.5Z" fill="#767676" stroke="#767676" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M4.25 4C4.38807 4 4.5 3.88807 4.5 3.75C4.5 3.61193 4.38807 3.5 4.25 3.5C4.11193 3.5 4 3.61193 4 3.75C4 3.88807 4.11193 4 4.25 4Z" fill="#767676" stroke="#767676" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M3.25 6.5C3.38807 6.5 3.5 6.38807 3.5 6.25C3.5 6.11193 3.38807 6 3.25 6C3.11193 6 3 6.11193 3 6.25C3 6.38807 3.11193 6.5 3.25 6.5Z" fill="#767676" stroke="#767676" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M6 1C3.25 1 1 3.25 1 6C1 8.75 3.25 11 6 11C6.463 11 6.824 10.627 6.824 10.156C6.824 9.9375 6.734 9.7385 6.6055 9.5935C6.4605 9.449 6.3865 9.2675 6.3865 9.031C6.3846 8.92096 6.40488 8.81165 6.44612 8.70961C6.48736 8.60757 6.54873 8.51488 6.62655 8.43705C6.70438 8.35923 6.79707 8.29786 6.89911 8.25662C7.00116 8.21538 7.11046 8.1951 7.2205 8.197H8.2185C9.744 8.197 10.996 6.9455 10.996 5.42C10.9825 3.006 8.7305 1 6 1Z" stroke="#767676" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_78_1647">
                    <rect width="12" height="12" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </i>
            <span>
              Text Color
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="5"
                viewBox="0 0 8 5"
                fill="none"
                className={`drop_icon ${dropdownOpen ? 'open' : ''}`}
              >
                <path d="M0.5 1L3.79167 4L7 1" stroke="#767676" />
              </svg>
            </span>
            {dropdownOpen && (
              
              <ul className="dropdown-menu">
                {dropdownOpen && (
                      <div>
                          <div className="dropdown-item">
                              <div
                                  className="flex_color"
                                  onClick={(e) => toggleColorPicker("correctWordColor", e)}
                              >
                                  Correct Word Color
                                  <div
                                      className="box1"
                                      style={{ backgroundColor: colors.correctWordColor }}
                                  ></div>
                              </div>
                          </div>
                          <div className="dropdown-item">
                              <div
                                  className="flex_color"
                                  onClick={(e) => toggleColorPicker("incorrectWordColor", e)}
                              >
                                  Incorrect Word Color
                                  <div
                                      className="box2"
                                      style={{ backgroundColor: colors.incorrectWordColor }}
                                  ></div>
                              </div>
                          </div>
                          <div className="dropdown-item">
                              <div
                                  className="flex_color"
                                  onClick={(e) => toggleColorPicker("approximateMatchColor", e)}
                              >
                                  Approximate Match Color
                                  <div
                                      className="box3"
                                      style={{ backgroundColor: colors.approximateMatchColor }}
                                  ></div>
                              </div>
                          </div>
                          {colorPickerVisible && (
                              <SketchPicker
                                  color={colors[colorPickerVisible]}
                                  onChangeComplete={(color) => handleColorChange(color, colorPickerVisible)}
                              />
                          )}
                      </div>
                  )}
              </ul>
            )}
          </div>


            
          <div className="menu-item dropdown-container" onClick={toggleDropdownlang}>
            <i aria-hidden="true" className="profile">
              {/* Text Color Icon */}
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <g clip-path="url(#clip0_78_1639)">
                  <path d="M2.5 4L5.5 7" stroke="#767676" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M2 7L5 4L6 2.5" stroke="#767676" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M1 2.5H7" stroke="#767676" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M3.5 1H4" stroke="#767676" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M11 11L8.5 6L6 11" stroke="#767676" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M7 9H10" stroke="#767676" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_78_1639">
                    <rect width="12" height="12" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </i>
            <span>
              Language
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="5"
                viewBox="0 0 8 5"
                fill="none"
                className={`drop_icon ${dropdownlang ? 'open' : ''}`}
              >
                <path d="M0.5 1L3.79167 4L7 1" stroke="#767676" />
              </svg>
            </span>
            {dropdownlang && (
              
              <ul className="dropdown-menu">
                {dropdownlang && (
                      <div>
                          <div className="dropdown-item" onClick={() => changeLanguage('English')}>
                              <div
                                  className="flex_color"
                              >
                                  English
                                  {currentLanguage === 'English' && (
                                    <div className="fa_check">
                                      <i className="fa fa-check" aria-hidden="true"></i>
                                    </div>
                                  )}
                              </div>
                          </div>
                          <div className="dropdown-item" onClick={() => changeLanguage('Arabic')}>
                              <div
                                  className="flex_color"
                              >
                                  Arabic
                                  {currentLanguage === 'Arabic' && (
                                    <div className="fa_check">
                                      <i className="fa fa-check" aria-hidden="true"></i>
                                    </div>
                                  )}
                              </div>
                          </div>
                      </div>
                  )}
              </ul>
            )}
          </div>

            <Link to='' className="menu-item">
              <i aria-hidden="true" className='profile'>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <path d="M4.5 10.5H2.5C2.23478 10.5 1.98043 10.3946 1.79289 10.2071C1.60536 10.0196 1.5 9.76522 1.5 9.5V2.5C1.5 2.23478 1.60536 1.98043 1.79289 1.79289C1.98043 1.60536 2.23478 1.5 2.5 1.5H4.5" stroke="#5A5A5A" stroke-width="0.916667" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8 8.5L10.5 6L8 3.5" stroke="#5A5A5A" stroke-width="0.916667" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.5 6H4.5" stroke="#5A5A5A" stroke-width="0.916667" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              </i>
              <span>Log out</span>
            </Link>
        </div>
        ) : null
      }
    </div>
  );
};

export default SideWindow;
