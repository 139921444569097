import axiosInstance from "../axios";

// Function to handle token blacklisting
export const blackilist = async () => {
    try {
        const response = await axiosInstance.post(
            'accounts/token/blacklist/'  // Post request to blacklist the current token
        );
        return response.data;  // Return the response data from the API
    } catch (error) {
        console.error('Error blacklisting token:', error);  // Log any errors
        throw error;  // Rethrow the error
    }
};

// Function to update profile colors based on the provided color values
export const updateProfileColors = async (colors) => {
    try {
        const response = await axiosInstance.post('/accounts/user/nav-profile/update/', {
            success: colors.correctWordColor,  // Correct word color
            fail: colors.incorrectWordColor,  // Incorrect word color
            warning: colors.approximateMatchColor,  // Approximate match color
        });
        return response.data;  // Return the updated data from the API
    } catch (error) {
        console.error('Error updating colors and language:', error);  // Log any errors
        throw error;  // Rethrow the error
    }
};

// Function to fetch the user's profile information (colors and language settings)
export const fetchUserProfile = async () => {
    try {
        const response = await axiosInstance.get('accounts/user/nav-profile/');  // Get the user profile data
        return response.data;  // Return the response data (success, fail, warning colors)
    } catch (error) {
        console.error('Error fetching profile:', error);  // Log any errors
        throw error;  // Rethrow the error
    }
};