import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from '../../axios';
import './SignUp.css';
import Logo from '../../components/Logo/Logo/Logo.jsx';

const Signup = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    password: '',
    confirm_password: '',
    accountType: 'user', // Default account type
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Create refs for each input field
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // CSRF token retrieval
  const getCSRFToken = () => {
    const match = document.cookie.match(/csrftoken=([^;]+)/);
    return match ? match[1] : '';
  };

  // Email validation function
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const {
      first_name,
      last_name,
      email,
      phone,
      password,
      confirm_password,
      accountType,
    } = formData;

    // Form validation
    if (!first_name) {
      firstNameRef.current.focus();
      setErrorMessage('First Name is required.');
      return;
    }
    if (!last_name) {
      lastNameRef.current.focus();
      setErrorMessage('Last Name is required.');
      return;
    }
    if (!email || !validateEmail(email)) {
      emailRef.current.focus();
      setErrorMessage('Please enter a valid email address.');
      return;
    }
    if (!phone) {
      phoneRef.current.focus();
      setErrorMessage('Phone number is required.');
      return;
    }
    if (!password) {
      passwordRef.current.focus();
      setErrorMessage('Password is required.');
      return;
    }
    if (!confirm_password) {
      confirmPasswordRef.current.focus();
      setErrorMessage('Confirm Password is required.');
      return;
    }
    if (password !== confirm_password) {
      setErrorMessage('Passwords do not match.');
      return;
    }

    try {
      const data = new FormData();
      const csrfToken = getCSRFToken();
      data.append('first_name', first_name);
      data.append('last_name', last_name);
      data.append('email', email);
      data.append('phone', phone);
      data.append('password', password);
      data.append('confirm_password', confirm_password);
      data.append('user_type', accountType);
      // data.append('dob', '2024-03-15'); // Example value
      // data.append('education_id', '1'); // Example value
      // data.append('country_id', '1'); // Example value
      // data.append('address', 'kg house'); // Example value
      // data.append('gender', 'male'); // Example value

      const response = await axiosInstance.post(
        'accounts/customer-registration/',
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-CSRFToken': csrfToken,
          },
        }
      );
      setSuccessMessage('Account created successfully!');
      setErrorMessage('');
      console.log('Response:', response.data);
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'An error occurred.');
      setSuccessMessage('');
      console.error(error, 'Error:', error.response?.data?.message);
    }
  };

  return (
    <div className='signup-container'>
      <Logo />
      <div className="signup-form">
        <div className="signup-form-header">
          <h2 className='signup-heading'>Create Account</h2>
          <p className='signup-trial-text'>Start your 00 day free trial</p>
        </div>
        <div className="account-type-radio">
          <label className='label_1'>
            <input
              type="radio"
              name="accountType"
              value="user"
              checked={formData.accountType === 'user'}
              onChange={handleInputChange}
            />
            <span className="custom-radio"></span>
            User Account
          </label>
          <label className='label_2'>
            <input
              type="radio"
              name="accountType"
              value="developer"
              checked={formData.accountType === 'developer'}
              onChange={handleInputChange}
            />
            <span className="custom-radio"></span>
            Developer Account
          </label>
        </div>
        <form className="signup-form-fields">
          <div className="signup-form-item">
            <label className='signup-input-label'>First Name</label>
            <input
              type="text"
              name="first_name"
              required
              className='signup-input-field'
              placeholder="John"
              value={formData.first_name}
              onChange={handleInputChange}
              ref={firstNameRef}
              autoComplete="off"
            />
          </div>
          <div className="signup-form-item">
            <label className='signup-input-label'>Last Name</label>
            <input
              type="text"
              name="last_name"
              required
              className='signup-input-field'
              placeholder="Doe"
              value={formData.last_name}
              onChange={handleInputChange}
              ref={lastNameRef}
              autoComplete="off"
            />
          </div>
          <div className="signup-form-item">
            <label className='signup-input-label'>Email</label>
            <input
              type="email"
              name="email"
              id="signup-email"
              required
              className='signup-input-field'
              placeholder="johndoe@mail.com"
              value={formData.email}
              onChange={handleInputChange}
              ref={emailRef}
              autoComplete="new-email"
            />
          </div>
          <div className="signup-form-item">
            <label className='signup-input-label'>Mobile Number</label>
            <input
              type="tel"
              name="phone"
              required
              className='signup-input-field'
              placeholder="1234567890"
              value={formData.phone}
              onChange={handleInputChange}
              ref={phoneRef}
              autoComplete="off"
            />
          </div>
          <div className="signup-form-item">
            <label className="signup-password-label">Password</label>
            <input
              type="password"
              name="password"
              id="signup-password"
              required
              className='signup-password-field'
              value={formData.password}
              onChange={handleInputChange}
              ref={passwordRef}
              autoComplete="new-password"
            />
          </div>
          <div className="signup-form-item">
            <label className="signup-password-label">Confirm Password</label>
            <input
              type="password"
              name="confirm_password"
              id="signup-confirm-password"
              required
              className='signup-password-field'
              value={formData.confirm_password}
              onChange={handleInputChange}
              ref={confirmPasswordRef}
              autoComplete="new-password"
            />
          </div>
          {errorMessage && <p className="error-message signup-error">{errorMessage}</p>}
          {successMessage && <p className="success-message">{successMessage}</p>}
          <button
            type="button"
            className='signup-submit-button'
            onClick={handleSubmit}
          >
            Create Account
          </button>
          <div className="signup-login-link-container">
            <p style={{ color: '#808080' }}>
              Already have an account?&nbsp;
            </p>
            <Link to="/login" className='signup-login-link'>
              Log In
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Signup;
