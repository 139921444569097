import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ButtonPrimaryWithIcon from '../../components/LandingPage/ButtonPrimaryWithIcon/ButtonPrimaryWithIcon.jsx';
import SideWindow from '../../components/LandingPage/sidewindow/sidewindow.jsx';

const LandingPage = ({ className, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();  // Get current location (route)
  const [isSideWindowOpen, setIsSideWindowOpen] = useState(false);

  const handleLoginClick = () => {
    navigate('/login');
  };

  const toggleSideWindow = () => {
    setIsSideWindowOpen(!isSideWindowOpen);
  };

  const menuItems = [
    { label: 'Pricing', icon: 'fa-credit-card', path: '/see-pricing' },
    { label: 'Login', icon: 'fa-sign-in', path: '/login' },
  ];

  // // Conditionally add user data if on "home" page
  // const user = location.pathname === '/' ? { name: 'Cooper Vaccaro', profileImage: '/path/to/user/image.jpg' } : null;

  return (
    <div className={"landing-page " + className} style={{ backgroundColor: "#E9EBFF", minHeight: "100vh" }}>
      <nav className="navbar navbar-expand-lg navbar-light pb-5">
        <div className="container-fluid">
          <div className="navbar-brand ms-lg-5">
            <img
              className="img-fluid"
              src={`${process.env.PUBLIC_URL}/assets/images/group-20.svg`}
              alt="Group 20"
              style={{ maxHeight: "50px" }}
            />
          </div>

          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleSideWindow} // Toggle side window on hamburger button click
            aria-controls="navbarContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{ border: "none" }}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarContent">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <p className="me-lg-4 see_pricing" style={{padding: '.9rem 0.1rem',cursor:'pointer'}}>See Pricing</p>
              </li>
              <li className="nav-item">
                <button
                  className="btn btn-outline-dark me-lg-5 px-5 login_button mt-2"
                  onClick={handleLoginClick} // Navigate to login on click
                >
                  Login
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div className="container-fluid py-4 pt-5">
        <div className="row justify-content-center text-center mb-4">
          <div className="col-12 col-md-8">
            <h1 className="heading">
              Your Ultimate Guide to Understanding,
              <span className="d-lg-block"> Analyzing, and Enjoying Literature</span>
            </h1>
          </div>
        </div>

        <div className="row justify-content-center text-center mb-4">
          <div className="col-md-6">
            <p className="para">
              Lorem ipsum dolor sit amet consectetur. Habitant accumsan ac pharetra porta. Pretium placerat ipsum
              nibh at elit arcu gravida vel ut. Posuere vitae adipiscing a integer sollicitudin. Nunc sapien dignissim
              eros nullam massa erat amet.
            </p>
          </div>
        </div>

        <div className="row justify-content-center mb-5">
          <div className="col-12 d-flex justify-content-center">
            <ButtonPrimaryWithIcon>
              <span>Click Me</span>
            </ButtonPrimaryWithIcon>
          </div>
        </div>
      </div>

      {/* Reusable side window component, pass user only if it's the home page */}
      <SideWindow 
        isOpen={isSideWindowOpen} 
        onClose={toggleSideWindow} 
        menuItems={menuItems} 
        // user={user}  // Pass user data only if on home page
      />
    </div>
  );
};

export default LandingPage;
