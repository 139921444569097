import axios from 'axios';
// Define the base URL as a constant
const BASE_URL = ' https://memorizing.technaureus.com/';

// Create an axios instance with the base URL
const axiosInstance = axios.create({
    baseURL: BASE_URL,  // Your Django API base URL
});

// Function to get the stored tokens (access and refresh tokens) from localStorage
const getTokens = () => ({
    token: localStorage.getItem('token'),           // Access token
    refreshToken: localStorage.getItem('refresh_token')  // Refresh token
});

// Add a request interceptor to include Authorization header if token exists
axiosInstance.interceptors.request.use(
    (config) => {
        const { token } = getTokens();

        // Paths to exclude from Authorization token (login, signup, and pricing)
        const excludedPaths = ['/login', '/signup', '/pricing'];  // Paths to exclude from token

        // If a token exists and the request is not to excluded paths, add the Authorization header
        if (token && !excludedPaths.some((path) => config.url.includes(path))) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;  // Continue with the request
    },
    (error) => {
        return Promise.reject(error);  // Reject the request if there's an error
    }
);

// Add a response interceptor to handle token expiration (401 error)
axiosInstance.interceptors.response.use(
    response => response,  // Pass through successful responses
    async (error) => {
        const { response } = error;
        
        // Check if the response status is 401 (Unauthorized), indicating the token has expired
        if (response && response.status === 401) {
            const { refreshToken } = getTokens();

            // If a refresh token exists, try to refresh the access token
            if (refreshToken) {
                try {
                    // Send a POST request to refresh the token
                    const refreshResponse = await axios.post(`${BASE_URL}accounts/token/refresh/`, {
                        refresh: refreshToken  // Send the refresh token
                    });

                    // Store the new access token
                    const newAccessToken = refreshResponse.data.access;

                    // Save the new access token to localStorage
                    localStorage.setItem('token', newAccessToken);

                    // Retry the original failed request with the new token
                    error.config.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    return axios(error.config);  // Retry the request with the updated token
                } catch (refreshError) {
                    // If refreshing the token fails, log the error, clear tokens, and redirect to login
                    console.error("Refresh token expired or invalid", refreshError);
                    localStorage.removeItem('token');
                    localStorage.removeItem('refresh_token');
                    window.location.href = '/login';  // Redirect to the login page
                }
            } else {
                // If no refresh token is available, log the user out and redirect to login
                console.error("No refresh token found");
                localStorage.removeItem('token');
                localStorage.removeItem('refresh_token');
                window.location.href = '/login';  // Redirect to login page
            }
        }
        return Promise.reject(error);  // Reject other types of errors
    }
);
// Export the axios instance for use in other parts of the application
export default axiosInstance;
