import React from 'react';
import { Link } from 'react-router-dom';
import './Logo.css';  // Import the CSS file for the logo

const Logo = () => {
  return (
    <div className="signup-logo">
      <Link to="/">
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/group-20.svg`}
          alt="Logo"
          className="signup-logo-img"
        />
      </Link>
    </div>
  );
};

export default Logo;
